
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import Menu from './pages/Menu/Menu';
import RecipesDownload from './pages/Recipes&download/Recipes&download';
import {DataContextProvider} from './Slider'
function App() {
  return (
    <div className="App">
        <DataContextProvider> 
     <Navbar/>
     <Home/>
     <About/>
   <Menu/>
   <RecipesDownload/>
   <Contact/>

     </DataContextProvider>
    </div>
  );
}

export default App;
