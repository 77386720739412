import React from 'react'
import FullMenu from '../../Components/FullMenu/FullMenu'
import Popularmenu from '../../Components/Popularmenu/Popularmenu'

export default function Menu () {
  return (
    <div>
    <Popularmenu/>
    <FullMenu/>
     
    </div>
  )
}
