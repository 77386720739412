import React from 'react'
import Header from '../../Components/Header/Header'

export default function Home() {
  return (
    <div >
     
        <Header/>
        

    </div>
  )
}