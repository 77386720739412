import React from 'react'
import Download from '../../Components/Download/Download'
import Recipes from '../../Components/Recipes/Recipes'


export default function RecipesDownload() {
  return (
    <div>
          <Recipes/>
        <Download/>
    </div>
  )
}
